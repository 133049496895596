<template>
    <div class="container-fluid mtb15 no-fluid">
        <div class="row sm-gutters">
            <div class="col-sm-12 col-md-12">
                <ul class="nav nav-tabs nav-pills settings-nav">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" :class="{ 'active': 'Dashboard' === $route.name }"
                            @click="openDashboard">
                            {{ getTradingMenuItem }}
                        </button>
                    </li>
                    <!--<li class="nav-item" role="presentation">
                        <button class="nav-link" :class="{ 'active': 'Trader' === $route.name }" @click="openTrader">
                            {{ getTradingMenuItem }}
                        </button>
                    </li>-->
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" :class="{ 'active': 'Wallet' === $route.name }" @click="openWallet">
                            {{ getWalletMenuItem }}
                        </button>
                    </li>
                </ul>                
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">{{ getBalanceSummaryLabel }}</h5>
                        <div class="d-flex justify-content-center">
                            <div class="form-inline my-auto">
                                <!-- graph -->
                                <div class="d-flex align-items-center justify-content-center">
                                    <GPieChart :height="120" :data="[parseFloat(totalEquitiesValue), parseFloat(getFreeFunds)]" :lbls="[getPositionsLabel, getFreeFundsLabel]"></GPieChart>
                                </div>
                                <!-- total assets -->
                                <div class="d-flex align-items-center justify-content-center">
                                    <h4>{{ formatPrice(totalBalance, getCurrency, 2).replace(" ", "&nbsp;") }}</h4>
                                </div>
                                <!-- total equities -->
                                <div class="form-group pt-0">
                                    <span class="dot_instruments" />&nbsp;<strong>{{ getPositionsLabel }}</strong><br />
                                    <span class="small">{{ formatPrice(totalEquitiesValue, getCurrency, 2).replace(" ", "&nbsp;") }}&nbsp;<small>({{
                                        formatNumber(getEquitiesPercentage) }}%)</small></span>
                                </div>
                                <!-- free funds -->
                                <div class="form-group pt-0">
                                    <span class="dot_money" />&nbsp;<strong>{{ getFreeFundsLabel }}</strong><br />
                                    <span class="small">{{ formatPrice(getFreeFunds, getCurrency, 2).replace(" ", "&nbsp;") }}</span>
                                </div>                                
                                <!-- in settlement buy -->
                                <div class="form-group pt-0">
                                    <strong>{{ getInSettlementLabel }} (-)</strong><br />
                                    <span class="small">{{ formatPrice(getTotalEquitiesInBuySettlementAsMoney, getCurrency, 2).replace(" ", "&nbsp;") }}</span>
                                </div>
                                <!-- in settlement sell -->
                                <div class="form-group pt-0">
                                    <strong>{{ getInSettlementLabel }} (+)</strong><br />
                                    <span class="small">{{ formatPrice(getTotalEquitiesInSellSettlementAsMoney, getCurrency, 2).replace(" ", "&nbsp;") }}</span>
                                </div>
                                <!-- total money -->
                                <div class="form-group pt-0">
                                    <strong>{{ getMoneyLabel }}</strong><br />
                                    <span class="small">{{ formatPrice(totalAssetsValue, getCurrency, 2).replace(" ", "&nbsp;") }}&nbsp;<small>({{
                                        formatNumber(getMoneyPercentage) }}%)</small></span>
                                </div>
                                <!-- guarantee deposit -->
                                <div class="form-group pt-0">
                                    <strong>{{ getGuaranteeDepositLabel }} (-)</strong><br />
                                    <span class="small">{{ formatPrice(getGuaranteeDeposit, getCurrency, 2).replace(" ", "&nbsp;") }}</span>
                                </div>
                                <!-- excess funds -->
                                <div class="form-group pt-0">
                                    <strong>{{ getExcessFundsLabel }}</strong><br />
                                    <span class="small">{{ formatPrice(getExcessFunds, getCurrency, 2).replace(" ", "&nbsp;") }}</span>
                                </div>
                                <!-- available to withdraw -->
                                <div class="form-group pt-0">
                                    <strong>{{ getAvailableToWithdrawLabel }}</strong><br />
                                    <span class="small">{{ formatPrice(getAvailableToWithdraw, getCurrency, 2).replace(" ", "&nbsp;") }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <!-- add funds -->
                    <a class="btn btn-primary w-100" target="_blanc" href="https://payments.eurofinance.bg/">{{ getAddFundsLabel }}</a>
                </div>
                <!--<div class="card mtb15">
                    <div class="card-header">
                        <button class="btn btn-primary w-100" type="button" data-bs-toggle="collapse" data-bs-target="#myPostionsCollapse" aria-expanded="false" aria-controls="myPostionsCollapse">
                            {{ getMyPositionsLabel }} {{ getTotalPositionsCount }}
                        </button>
                    </div>
                    <div class="collapse" id="myPostionsCollapse">
                        <div class="card card-body">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>{{ getAssetLabel }}</th>
                                            <th>{{ getCountLabel }}</th>
                                            <th>&Sigma;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="0 < getTotalEquitiesCount">
                                            <td>{{ getEquitiesLabel }}</td>
                                            <td>{{ getTotalEquitiesCount }}</td>
                                            <td>{{ formatNumber(getTotalEquitiesValue) }}</td>
                                        </tr>
                                        <tr v-if="0 < getTotalBondsCount">
                                            <td>{{ getBondsLabel }}</td>
                                            <td>{{ getTotalBondsCount }}</td>
                                            <td>{{ formatNumber(getTotalBondsValue) }}</td>
                                        </tr>
                                        <tr v-if="0 < getTotalCompInstrumentsCount">
                                            <td>{{ getCompInstrumentsLabel }}</td>
                                            <td>{{ getTotalCompInstrumentsCount }}</td>
                                            <td>{{ formatNumber(getTotalCompInstrumentsValue) }}</td>
                                        </tr>
                                        <tr v-if="0 < getTotalFundsCount">
                                            <td>{{ getFundsLabel }}</td>
                                            <td>{{ getTotalFundsCount }}</td>
                                            <td>{{ formatNumber(getTotalFundsValue) }}</td>
                                        </tr>
                                        <tr v-if="0 < getTotalReitsCount">
                                            <td>{{ getReitsLabel }}</td>
                                            <td>{{ getTotalReitsCount }}</td>
                                            <td>{{ formatNumber(getTotalReitsValue) }}</td>
                                        </tr>
                                        <tr v-if="0 < getTotalRightsCount">
                                            <td>{{ getRightsLabel }}</td>
                                            <td>{{ getTotalRightsCount }}</td>
                                            <td>{{ formatNumber(getTotalRightsValue) }}</td>
                                        </tr>
                                        <tr v-if="0 < getTotalCfdCount">
                                            <td>{{ getCfdLabel }}</td>
                                            <td>{{ getTotalCfdCount }}</td>
                                            <td>{{ formatNumber(getTotalCfdValue) }}</td>
                                        </tr>
                                        <tr v-if="0 < getTotalMarginCount">
                                            <td>{{ getMarginLabel }}</td>
                                            <td>{{ getTotalMarginCount }}</td>
                                            <td>{{ formatNumber(getTotalMarginValue) }}</td>
                                        </tr>
                                        <tr v-if="0 < getTotalNettingCount">
                                            <td>{{ getNettingLabel }}</td>
                                            <td>{{ getTotalNettingCount }}</td>
                                            <td>{{ formatNumber(getTotalNettingValue) }}</td>
                                        </tr>
                                        <tr v-if="0 < getTotalRepoCount">
                                            <td>{{ getRepoLabel }}</td>
                                            <td>{{ getTotalRepoCount }}</td>
                                            <td>{{ formatNumber(getTotalRepoValue) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import UiUtils from "@/assets/js/ui_utils.js";
import Translations from "@/lang/translations.js";
import GPieChart from "../components/GPieChart.vue";

export default {
    components: {
        GPieChart
    },
    methods: {
        openWallet() {
            this.$router.push({ name: "Wallet" });
        },
        openDashboard() {
            this.$router.push({ name: "Dashboard" });
        },
        openProfile() {
            this.$router.push({ name: "Profile" });
        },
        openTrader() {
            this.$router.push({ name: "Trader" });
        },
        getUserLanguage() {
            return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
        },
        formatNumber(val) {
            return UiUtils.formatNumber(val);
        },
        formatPrice(val, curr, autoDigits = null) {
            return UiUtils.formatPrice(val, curr, this.getUserLanguage(), autoDigits);
        }
    },
    computed: {    
        getUserType() {
            return this.$store.getters["getUserType"];
        },           
        getCurrency() {
            return this.$store.getters["getCurrency"].toUpperCase();
        }, 
        getDashboardMenuItem() {
            return Translations.getDashboardMenuItem(this.getUserLanguage());
        },
        getAddFundsLabel() {
            return Translations.getAddFundsLabel(this.getUserLanguage());
        },
        getTradingMenuItem() {
            return Translations.getTradingMenuItem(this.getUserLanguage());
        },
        getWalletMenuItem() {
            return Translations.getWalletMenuItem(this.getUserLanguage());
        },
        getBalanceSummaryLabel() {
            return Translations.getBalanceSummaryLabel(this.getUserLanguage());
        },
        getAccountSummaryLabel() {
            return Translations.getAccountSummaryLabel(this.getUserLanguage());
        },
        getMoneyLabel() {
            return Translations.getMoneyLabel(this.getUserLanguage());
        },
        getPositionsLabel() {
            return Translations.getPositionsLabel(this.getUserLanguage());
        }, 
        getMyPositionsLabel() {
            return Translations.getMyPositionsLabel(this.getUserLanguage());
        },
        getEquitiesLabel() {
            return Translations.getEquitiesLabel(this.getUserLanguage());
        },
        getBondsLabel() {
            return Translations.getBondsLabel(this.getUserLanguage());
        },
        getCompInstrumentsLabel() {
            return Translations.getCompInstrumentsLabel(this.getUserLanguage());
        },
        getFundsLabel() {
            return Translations.getFundsLabel(this.getUserLanguage());
        },
        getReitsLabel() {
            return Translations.getReitsLabel(this.getUserLanguage());
        },
        getRightsLabel() {
            return Translations.getRightsLabel(this.getUserLanguage());
        },
        getCfdLabel() {
            return Translations.getCfdLabel(this.getUserLanguage());
        },
        getMarginLabel() {
            return Translations.getMarginLabel(this.getUserLanguage());
        },
        getNettingLabel() {
            return Translations.getNettingLabel(this.getUserLanguage());
        },
        getRepoLabel() {
            return Translations.getRepoLabel(this.getUserLanguage());
        },
        getAssetLabel() {
            return Translations.getAssetLabel(this.getUserLanguage());
        },
        getCountLabel() {
            return Translations.getCountLabel(this.getUserLanguage());
        },
        getInSettlementLabel() {
            return Translations.getInSettlementLabel(this.getUserLanguage());
        },
        getFreeFundsLabel() {
            return Translations.getFreeFundsLabel(this.getUserLanguage());
        },
        getGuaranteeDepositLabel() {
            return Translations.getGuaranteeDepositLabel(this.getUserLanguage());
        },
        getExcessFundsLabel() {
            return Translations.getExcessFundsLabel(this.getUserLanguage());
        },
        getAvailableToWithdrawLabel() {
            return Translations.getAvailableToWithdrawLabel(this.getUserLanguage());
        },
        totalAssetsValue() {
            const currency = this.getCurrency;
            const userWallet = this.$store.getters["getWallet"];
            return UiUtils.getTotalMoney(userWallet, currency);
        },
        totalEquitiesValue() {
            const currency = this.getCurrency;
            const userWallet = this.$store.getters["getWallet"];
            const stocks = this.$store.getters["getStocks"];
            const indexedStocks = this.$store.getters["getIndexedStocks"];

            return UiUtils.getTotalEquitiesAsMoney(stocks, indexedStocks, userWallet, currency);
        },
        totalBalance() {
            return (parseFloat(this.totalEquitiesValue) + parseFloat(this.getFreeFunds));
        },
        getMoneyPercentage() {
            return parseFloat(this.totalAssetsValue) / parseFloat(this.totalBalance) * 100;
        },
        getEquitiesPercentage() {
            return parseFloat(this.totalEquitiesValue) / parseFloat(this.totalBalance) * 100;
        },
        getTotalPositionsCount() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let allAssetsCount = 0;
            userWallet.s.forEach(element => {
                allAssetsCount += element.am;
            });
            return allAssetsCount;
        },
        getTotalEquitiesCount() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (1 === element.stid) {
                    assetsCount += element.am;
                }
            });
            return assetsCount;
        },
        getTotalEquitiesValue() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (1 === element.stid) {
                    assetsCount += element.mp;
                }
            });
            return assetsCount;
        },
        getTotalBondsCount() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (2 === element.stid) {
                    assetsCount += element.am;
                }
            });
            return assetsCount;
        },
        getTotalBondsValue() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (2 === element.stid) {
                    assetsCount += element.mp;
                }
            });
            return assetsCount;
        },
        getTotalCompInstrumentsCount() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (3 === element.stid) {
                    assetsCount += element.am;
                }
            });
            return assetsCount;
        },
        getTotalCompInstrumentsValue() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (3 === element.stid) {
                    assetsCount += element.mp;
                }
            });
            return assetsCount;
        },
        getTotalFundsCount() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (4 === element.stid) {
                    assetsCount += element.am;
                }
            });
            return assetsCount;
        },
        getTotalFundsValue() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (4 === element.stid) {
                    assetsCount += element.mp;
                }
            });
            return assetsCount;
        },
        getTotalReitsCount() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (5 === element.stid) {
                    assetsCount += element.am;
                }
            });
            return assetsCount;
        },
        getTotalReitsValue() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (5 === element.stid) {
                    assetsCount += element.mp;
                }
            });
            return assetsCount;
        },
        getTotalRightsCount() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (6 === element.stid) {
                    assetsCount += element.am;
                }
            });
            return assetsCount;
        },
        getTotalRightsValue() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (6 === element.stid) {
                    assetsCount += element.mp;
                }
            });
            return assetsCount;
        },
        getTotalCfdCount() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (7 === element.stid) {
                    assetsCount += element.am;
                }
            });
            return assetsCount;
        },
        getTotalCfdValue() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (7 === element.stid) {
                    assetsCount += element.mp;
                }
            });
            return assetsCount;
        },
        getTotalMarginCount() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (8 === element.stid) {
                    assetsCount += element.am;
                }
            });
            return assetsCount;
        },
        getTotalMarginValue() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (8 === element.stid) {
                    assetsCount += element.mp;
                }
            });
            return assetsCount;
        },
        getTotalNettingCount() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (9 === element.stid) {
                    assetsCount += element.am;
                }
            });
            return assetsCount;
        },
        getTotalNettingValue() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (9 === element.stid) {
                    assetsCount += element.mp;
                }
            });
            return assetsCount;
        },
        getTotalRepoCount() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (10 === element.stid) {
                    assetsCount += element.am;
                }
            });
            return assetsCount;
        },
        getTotalRepoValue() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let assetsCount = 0;
            userWallet.s.forEach(element => {
                if (10 === element.stid) {
                    assetsCount += element.mp;
                }
            });
            return assetsCount;
        },
        getTotalEquitiesInBuySettlementAsMoney() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            return UiUtils.getTotalEquitiesInBuySettlementAsMoney(userWallet, this.getCurrency);
        },
        getTotalEquitiesInSellSettlementAsMoney() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            return UiUtils.getTotalEquitiesInSellSettlementAsMoney(userWallet, this.getCurrency);
        },
        getFreeFunds() {
            // cash - getTotalEquitiesInBuySettlementAsMoney + getTotalEquitiesInSellSettlementAsMoney
            return parseFloat(this.totalAssetsValue)
                + parseFloat(this.getTotalEquitiesInBuySettlementAsMoney)
                + parseFloat(this.getTotalEquitiesInSellSettlementAsMoney);
        },
        getGuaranteeDeposit() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            return UiUtils.getGuaranteeDeposit(userWallet, this.getCurrency);
        },
        getExcessFunds() {
            const exFunds = parseFloat(this.getFreeFunds) - parseFloat(this.getGuaranteeDeposit);
            return exFunds < 0
                ? 0
                : exFunds;
        },
        getAvailableToWithdraw() {
            const available = parseFloat(this.totalAssetsValue)
                + parseFloat(this.getTotalEquitiesInBuySettlementAsMoney)
                - parseFloat(this.getGuaranteeDeposit);
            return available < 0
                ? 0
                : available;
        }
    },   
}
</script>

<style scoped>
span.small {
  font-size: smaller;
}
.dot_money {
    height: 10px;
    width: 10px;
    background-color: #00E396;
    border-radius: 50%;
    display: inline-block;
}
.dot_instruments {
    height: 10px;
    width: 10px;
    background-color: #008FFB;
    border-radius: 50%;
    display: inline-block;
}
</style>