/* eslint-disable */
<template>
  <div class="container-fluid mtb15 no-fluid">    
    <div class="row sm-gutters">
      <!-- Menu -->
      <div v-if="1200 < getWindowsWidth" class="col-sm-12 col-md-2">
        <Menu></Menu>
      </div>
      <!-- Stocks and watchlists dashboard -->
      <!-- Modal confirm delete -->
      <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="confirmDeleteLabel" aria-hidden="true"
        :class="{ 'show': watchlistSelectionModalShow, 'd-block': watchlistSelectionModalActive }">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="confirmDeleteLabel">{{ getConfirmDeleteValueLabel }}</h5>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-dismiss="modal" @click="deleteWatchlist()">{{
                getConfirmLabel
                }}</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal"
                @click="watchlistSelectionModalActive = false; watchlistSelectionModalShow = false;">{{
                getCloseLabel
                }}</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="watchlistSelectionModalActive" class="modal-backdrop fade show"></div>
      <!-- Modal trade confirmed -->
      <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="tradeConfirmedLabel" aria-hidden="true"
        :class="{ 'show': tradeConfirmedModalShow, 'd-block': tradeConfirmedModalActive }">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-body">
                <TradeConfirmed :order="orderModel" />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal"
                @click="tradeConfirmedModalActive = false; tradeConfirmedModalShow = false;">{{
                getCloseLabel
                }}</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal show trading in responsive -->
      <div  v-if="1200 > getWindowsWidth" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="showTradingInResponsive" aria-hidden="true"
        :class="{ 'show': tradingInResponsiveModalShow, 'd-block': tradingInResponsiveModalActive }">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" 
                    @click="tradingInResponsiveModalShow = false; tradingInResponsiveModalActive = false; showTradingInResponsive = false;">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <Trading :key="this.selectedItemCode"></Trading>
            </div>
          </div>
        </div>
      </div>      
      <div class="col-sm-12" :class="{'col-md-10' : 1200 < getWindowsWidth }">
        <div class="row">
          <!-- watchlists -->
          <div v-if="!showTradingInResponsive" id="watchlists-column" class="col-sm-12 h-100" :class="{ 'col-md-8': 1200 < getWindowsWidth }">           
            <div class="row">
              <div class="input-group w-100">
                <ul class="nav nav-tabs nav-pills text-nowrap" style="overflow-x: auto; max-width: 100%; max-height: auto; flex: 1; flex-wrap: nowrap;"
                  :key="getUserWatchLists.length">
                  <li v-for="(watchlist) in getUserWatchLists" :key="watchlist.wln"
                    class="nav-item d-flex justify-content-between align-items-center" role="presentation">
                    <div class="d-flex">
                      <div>
                        <button v-if="watchlist.wln.toString().toUpperCase() !== 'DEFAULT'" class="nav-link"
                          :class="{ 'active': 0 === watchlist.wln.toString().toUpperCase().localeCompare(activeUserWatchlist.toUpperCase()), 'xsmall-font-size': 1200 > getWindowsWidth }"
                          :id="watchlist.wln + '-id'" type="button" @click.prevent="userWatchlistNameClick(watchlist)">
                          {{ watchlist.wln.toString().toUpperCase() }}
                        </button>
                        <button v-else class="nav-link"
                          :class="{ 'active': 0 === watchlist.wln.toString().toUpperCase().localeCompare(activeUserWatchlist.toUpperCase()), 'xsmall-font-size': 1200 > getWindowsWidth }"
                          :id="watchlist.wln + '-id'" type="button" @click.prevent="userWatchlistNameClick(watchlist)">
                          {{ getWatchlistLabel.toUpperCase() }}
                        </button>
                      </div>
                    </div>
                    <div>
                      <div v-if="canDeleteWatchlist(watchlist.wln)" class="text-end">
                        <button type="button" class="btn-close"
                          @click.prevent="setWatchlistToDelete(watchlist.wln)"></button>
                      </div>
                    </div>
                  </li>
                  <li v-for="(watchlist) in getIndexedWatchlists" :key="watchlist.wln"
                    class="nav-item d-flex justify-content-between align-items-center" role="presentation">
                    <div class="d-flex">
                      <div>
                        <button class="nav-link"
                          :class="{ 'active': 0 === watchlist.wln.toString().toUpperCase().localeCompare(activeUserWatchlist.toUpperCase()), 'xsmall-font-size': 1200 > getWindowsWidth }"
                          :id="watchlist.wln + '-id'" type="button" @click.prevent="userWatchlistNameClick(watchlist);">
                          {{ watchlist.wln.toString().toUpperCase() }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="input-group-append">
                  <!-- selections -->
                  <a class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <img width="15" src="../assets/images/filter.svg" alt="" />
                  </a>
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li><a class="dropdown-item" href="#" @click.prevent="activeTop10Item = 'INDEX'; activeUserWatchlist = lastActiveUserWatchlist;">{{ capitalizeFirstLetter(getSortDefaultLabel) }}</a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="activeTop10Item = 'GAINERS'; lastActiveUserWatchlist = activeUserWatchlist; activeUserWatchlist = '';">{{ capitalizeFirstLetter(getGainersLabel) }}</a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="activeTop10Item = 'LOSERS'; lastActiveUserWatchlist = activeUserWatchlist; activeUserWatchlist = '';">{{ capitalizeFirstLetter(getLosersLabel) }}</a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="activeTop10Item = 'MOST TRADED'; lastActiveUserWatchlist = activeUserWatchlist; activeUserWatchlist = '';">{{ capitalizeFirstLetter(getMostTradedLabel) }}</a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="activeTop10Item = 'INDEXES'; lastActiveUserWatchlist = activeUserWatchlist; activeUserWatchlist = '';">{{ capitalizeFirstLetter(getIndexesLabel) }}</a></li>
                  </ul>
                  <!-- sorting -->
                  <a class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <img width="15" src="../assets/images/sort.svg" alt="" />
                  </a>
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li>
                      <a class="dropdown-item" href="#" @click.prevent="activeSortingOption = 0;">
                        {{ capitalizeFirstLetter(getSortDefaultLabel) }}
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#" @click.prevent="activeSortingOption = 1;">
                        {{ capitalizeFirstLetter(getSortSymbolAscLabel) }}
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#" @click.prevent="activeSortingOption = 2;">
                        {{ capitalizeFirstLetter(getSortSymbolDescLabel) }}
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#" @click.prevent="activeSortingOption = 3;">
                        {{ capitalizeFirstLetter(getSortNameAscLabel) }}
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#" @click.prevent="activeSortingOption = 4;">
                        {{ capitalizeFirstLetter(getSortNameDescLabel) }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>            
            <!-- gainers -->
            <div v-if="0 === activeTop10Item.localeCompare('GAINERS')" class="table-responsive">
              <Top10ListItem :items="getGainers" />
            </div>
            <!-- losers -->
            <div v-if="0 === activeTop10Item.localeCompare('LOSERS')" class="table-responsive">
              <Top10ListItem :items="getLosers" />
            </div>
            <!-- most traded -->
            <div v-if="0 === activeTop10Item.localeCompare('MOST TRADED')" class="table-responsive">
              <Top10ListItem :items="getMostTraded" />
            </div>            
            <!-- indexes -->
            <div v-if="0 === activeTop10Item.localeCompare('INDEXES')" class="table-responsive">
              <table class="table w-100">
                <thead>
                  <tr>
                    <th class="name-column" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getIndexLabel.toUpperCase() }}</th>
                    <th class="data-column" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{ getChangeLabel.toUpperCase() }}</th>
                    <th class="data-column" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getChangeLabel.toUpperCase() }}</th>
                    <th class="data-column" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getValueLabel.toUpperCase() }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="indx in getIndexes" :key="indx.sc">
                      <td>
                        <strong>{{ indx?.sc.toString().toUpperCase() }}</strong>
                      </td>
                      <td class="stock-data xsmall-font-size align-text-right">
                        <div v-show="isNaN(parseFloat(indx?.ch))">
                            <span :class="getIndexItemColor(indx?.ch)">0%</span>
                        </div>
                        <div v-show="0 < parseFloat(indx?.ch)">
                            <span :class="getIndexItemColor(indx?.ch)">+{{ parseNumber(indx?.ch) }}%</span>
                        </div>
                        <div v-show="0 >= parseFloat(indx?.ch)">
                            <span :class="getIndexItemColor(indx?.ch)">{{ parseNumber(indx?.ch) }}%</span>
                        </div>
                      </td>
                      <td v-show="isNaN(parseFloat(indx?.dif))" align="center" colspan="3">
                          <Preloader></Preloader>
                      </td>
                      <td class="stock-data xsmall-font-size align-text-right" :class="getIndexItemColor(indx?.dif)">
                        {{ parseNumber(indx?.dif) }}
                      </td>
                      <td class="stock-data xsmall-font-size align-text-right">
                        {{ formatPrice(indx?.val, getIndexCurrency(indx?.sc), 2).replace(' ', '&nbsp;') }}
                      </td>
                  </tr>                  
                </tbody>
              </table>
            </div>
            <!-- watchlists data -->
            <KeepAlive v-if="0 === activeTop10Item.localeCompare('INDEX')">
              <StockList class="mt-3"></StockList>
            </KeepAlive>
          </div>          
          <!-- Trading -->
          <div v-if="1200 < getWindowsWidth" id="trading-column" class="col-sm-12 h-100" :class="{ 'col-md-4': 1200 < getWindowsWidth }">
            <Trading :key="this.selectedItemCode"></Trading>
          </div>
        </div>
      </div>
    </div>
    <!-- trade button -->
    <button v-if="1200 > getWindowsWidth && !showTradingInResponsive" id="tradeButton" class="btn position-fixed bottom-0 end-0" 
      @click.prevent="showTradingInResponsive = true; tradingInResponsiveModalShow = true; tradingInResponsiveModalActive = true;">
      <img src="../assets/images/trade_button.svg" alt="">
    </button>
  </div>
</template>

<script>
import http from "@/axios/http-common";
import StockList from '../components/StockList.vue';
import Top10ListItem from '../components/Top10ListItem.vue';
import Menu from '../components/Menu.vue';
import Trading from '../components/Trading.vue';
import UiUtils from "@/assets/js/ui_utils.js";
import ApiUtils from "@/assets/js/api_utils.js";
import Translations from "@/lang/translations.js";
import TradeConfirmed from '@/components/TradeConfirmed.vue';
import Preloader from "@/components/Preloader.vue";

/**
 * this method identifies unique stocks in list with O(n) complexity (fastest possible)
 * taken from https://stackoverflow.com/questions/15125920/how-to-get-distinct-values-from-an-array-of-objects-in-javascript
 */
function onlyUnique(array) {
  var unique = [];
  var distinct = [];
  for (let i = 0; i < array.length; i++) {
    if (!unique[array[i].cMIC + array[i].code]) {
      distinct.push(array[i]);
      unique[array[i].cMIC + array[i].code] = 1;
    }
  }

  return distinct;
}

// https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers, 
     * and you may want to customize it to your needs
     */
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}

export default {
  components: {
    StockList,
    Top10ListItem,
    Trading,
    Menu,
    TradeConfirmed,
    Preloader
  },
  data() {
    return {
      selectedItemCode: "",             
      /* active modal dialog for watchlist selection */
      watchlistSelectionModalActive: false,
      /* show modal dialog for watchlist selection */
      watchlistSelectionModalShow: false,
      watchlistNameToDelete: "",
      /* 0 - all exchanges, other: exchange id */
      selectedExchange: this.getSelectedExchangeCode,
      /* currently selected user watchlist */
      activeUserWatchlist: "",
      /* last selected selected user watchlist */
      lastActiveUserWatchlist: "",
      /* currently selected index watchlist */
      activeIndexWatchlist: "",
      /* last selected selected index watchlist */
      lastActiveIndexWatchlist: "",      
      /* top 10 selected item */
      activeTop10Item: "INDEX",
      tradeConfirmedModalActive: false,
      tradeConfirmedModalShow: false,
      orderModel: {},
      hasReestablishingConnectionMessage: false,
      sseVerifier: null,
      hasEvents: false,
      sseClient: null,
      activeSortingOption: 0,
      showTradingInResponsive: false,
      tradingInResponsiveModalShow: false,
      tradingInResponsiveModalActive: false
    }
  },
  watch: {
    getSelectedTradingCode: function (newVal, oldVal) {
      if (oldVal !== newVal) {
        (async () => await this.setQuoteTr(newVal))();
      }
    },
    getSelectedExchangeCode: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedExchange = parseInt(newVal);
        // get top ten and indexes accorting to selection
        (async () => {
          this.$store.commit("setAppStateNotReady");
          await this.getAllTopTen();
          this.$store.commit("setAppStateReady");
        })();
      }
    },
    activeTop10Item: function(newVal) {
      if (0 !== newVal.localeCompare('INDEX')) {
        this.lastActiveIndexWatchlist = this.activeIndexWatchlist;
        this.activeIndexWatchlist = "";
      } else {
        this.activeIndexWatchlist = this.lastActiveIndexWatchlist;
      }
    },
    activeSortingOption: function(newVal) {
      this.$store.commit("setWatchlistSortingOption", newVal);
    },
    stopSseState: function (newVal) {
      if (newVal) {
        if (this.sseClient) {
          try {
            this.sseClient.close();
          } catch (err) {
            console.error(err);
          }
          this.$store.commit("setStopSse", false);
        }
      }
    }    
  },
  methods: {
    getIndexItemColor(change) {
      if (isNaN(parseFloat(change)) || 0 === parseFloat(change)) {
        return "blackColor";
      }
      if (0 > parseFloat(change)) {
        return "redColor";
      }
      return "greenColor";
    },
    formatPrice(val, curr, autoDigits = null) {
      return UiUtils.formatPrice(val, curr, this.getUserLanguage, autoDigits);
    },
    capitalizeFirstLetter(string) {
      return string
        ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
        : "";
    },
    getIndexCurrency(indexName) {
      const exchanges = this.$store.getters["getExchanges"];
      return UiUtils.getIndexCurrencyByName(exchanges, indexName);
    },
    getUserType() {
      return this.$store.getters["getUserType"];
    },
    parseNumber(num) {
      return UiUtils.formatNumber(num);
    },
    formOrderObject(event) {
      this.orderModel.type = this.getOrderType(event.t);
      this.orderModel.ex = event.xc;
      this.orderModel.code = event.sc;
      this.orderModel.name = 0 === 'BG'.localeCompare(this.getUserLanguage)
        ? event.nBG
        : event.nEN;
      this.orderModel.amount = this.parseNumber(event.qty);
      this.orderModel.price = this.parseNumber(event.sp);
      this.orderModel.price_total = this.parseNumber(event.pt);
      this.orderModel.currency = event.cc;
      this.orderModel.oid = event.id;
      this.orderModel.fee = this.parseNumber(event.fee);
      this.orderModel.time = this.getDate(event.d);
      this.orderModel.total_amount = this.parseNumber(event.pt + event.fee);
    },    
    setupStream() {
      const user = this.$store.getters["getUser"];
      if (!user) {
        return;
      }
      try {
        if (0 === this.getUserType()) {
          // sandbox
          this.sseClient = new EventSource("https://sse.sandbox.efocs.app/api/sse?vn=100&os=b&id=" + encodeURIComponent(user.id) +
            "&key=" + encodeURIComponent(user.key));
        } else {
          // production
          this.sseClient = new EventSource("https://sse.prod.efocs.app/api/sse?vn=100&os=b&id=" + encodeURIComponent(user.id) +
            "&key=" + encodeURIComponent(user.key));
        }

        // verify we have valid connection
        if (!this.sseClient) {
          return;
        }
        // show message if connection has been lost
        if (this.hasReestablishingConnectionMessage) {
          const reestablishConnectionMessage = this.getReestablishedConnectionLabel;
          // show user info
          this.$toast.success(reestablishConnectionMessage, {
            "duration": 5000,
            "pauseOnHover": true
          });
          this.$store.commit("addSystemMessage", {
            date: (new Date()).getTime(),
            mess: reestablishConnectionMessage,
            error: false
          });
          this.hasReestablishingConnectionMessage = false;
        }

        this.sseClient.addEventListener("HEARTBEAT", event => {
          this.hasEvents = true;
        }, { passive: true });
        this.sseClient.addEventListener("SESSION_CLOSED", event => {
          console.log('SESSION_CLOSED')
          this.hasEvents = true;
          // logout
          ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1);       
        }, { passive: true });
        this.sseClient.addEventListener("QUOTE", event => {
          try { 
            this.$store.commit("updateQuote", JSON.parse(event.data));
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });
        this.sseClient.addEventListener("QUOTE_TR", event => {
          this.hasEvents = true;
          try {
            this.$store.commit("setQuoteTrading", JSON.parse(event.data));
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });      
        this.sseClient.addEventListener("QUOTE_EX", event => {
          this.hasEvents = true;
          try {
            this.$store.commit("setQuoteExtended", JSON.parse(event.data));
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });      
        this.sseClient.addEventListener("DEPTH", event => {
          this.hasEvents = true;
          try {
            this.$store.commit("setDepth", JSON.parse(event.data));
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });      
        this.sseClient.addEventListener("PHASE", event => {
          this.hasEvents = true;
          try {
            this.$store.commit("upsertExchangesPhases", JSON.parse(event.data));
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });      
        this.sseClient.addEventListener("WALLET", event => {
          this.hasEvents = true;
          try {
            this.$store.commit("setWallet", JSON.parse(event.data));
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });
        this.sseClient.addEventListener("GAINER_LIST", event => {
          this.hasEvents = true;
          try {
            this.$store.commit("updateGainersList", JSON.parse(event.data));
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });
        this.sseClient.addEventListener("GAINER", event => {
          this.hasEvents = true;
          try {
            this.$store.commit("updateGainers", JSON.parse(event.data));
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });
        this.sseClient.addEventListener("LOSER_LIST", event => {
          this.hasEvents = true;
          try {
            this.$store.commit("updateLosersList", JSON.parse(event.data));
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });
        this.sseClient.addEventListener("LOSER", event => {
          this.hasEvents = true;
          try {
            this.$store.commit("updateLosers", JSON.parse(event.data));
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });
        this.sseClient.addEventListener("MOST_TRADED_LIST", event => {
          this.hasEvents = true;
          try {
            this.$store.commit("updateMostTradedList", JSON.parse(event.data));
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });
        this.sseClient.addEventListener("MOST_TRADED", event => {
          this.hasEvents = true;
          try {
            this.$store.commit("updateMostTraded", JSON.parse(event.data));
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });
        this.sseClient.addEventListener("INDEX", event => {
          this.hasEvents = true;
          try {
            this.$store.commit("updateIndex", JSON.parse(event.data));
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });
        this.sseClient.addEventListener("EXECUTED", event => {
          this.hasEvents = true;
          try {
            const orderData = JSON.parse(event.data);
            this.formOrderObject(orderData);
            this.tradeConfirmedModalShow = true;
            this.tradeConfirmedModalActive = true;          
            let mess = "";
            if (0 === "BG".localeCompare(this.getUserLanguage)) {
              mess = "Вашата поръчка №";
            } else {
              mess = "Your order #";
            }
            mess = mess + orderData.id + ", " + this.getDate(orderData.d) +
              ", " + this.getOrderType(orderData.t) + " " + orderData.sc + "(" + this.getStockName(orderData.sc, orderData.ex) + ") " +
              orderData.qty + "@" + orderData.sp + " " + orderData.cc;
            if (0 === "BG".localeCompare(this.getUserLanguage)) {
              mess = mess + " е изпълнена";
            } else {
              mess = mess + " is completed";
            }
            this.$toast.success(mess, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: mess,
              error: false
            });          
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });      
        this.sseClient.addEventListener("REJECTED", event => {
          this.hasEvents = true;
          try {
            const orderData = JSON.parse(event.data);
            let mess = "";
            if (0 === "BG".localeCompare(this.getUserLanguage)) {
              mess = "Вашата поръчка №";
            } else {
              mess = "Your order #";
            }
            const stockName = orderData.nBG 
              ? orderData.nBG: orderData.nEN;
            mess = mess + orderData.oid + ", " + this.getDate(orderData.ts) +
              ", " + orderData.sc + "(" + stockName + ") " +
              orderData.qty + "@" + orderData.sp + " " + orderData.cc;
            if (0 === "BG".localeCompare(this.getUserLanguage)) {
              mess = mess + " е отказана";
            } else {
              mess = mess + " is rejected";
            }
            this.$toast.error(mess, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: mess,
              error: true
            });
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });
        this.sseClient.addEventListener("error", event => {
          this.hasEvents = false;
          try { 
            if (event.readyState == EventSource.CLOSED) {
              console.log(EventSource);
            }          
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });
      } catch (error) {
        console.error(error);
      }
    },
    getDate(unix) {
      return UiUtils.parseUnixTime(unix);
    },
    getOrderType(ot) {
      return 'b' === ot || 'B' === ot ? 
        Translations.getBuyLabel(this.getUserLanguage) :
        Translations.getSellLabel(this.getUserLanguage);
    }, 
    async getExchangesAndStocks() {
      if (0 !== this.$store.getters["getExchanges"].length) {
        return;
      }      
      const user = this.$store.getters["getUser"];
      // get exchanges and stocks
      try {
        const res = await http.getApiClient(this.getUserType()).get("", {
          params: {
            a: "get_exchanges_stocks_ex",
            id: user.id,
            key: user.key
          }});
        if (res) {
          // check answer
          if (ApiUtils.isApiResponseInvalidSession(res)) {
            this.$store.commit("setAppStateReady");
            // logout
            setTimeout(
              ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
              500);
            return;
          }          
          if (0 === res.data.ec) {
            // set exchanges
            this.$store.commit("setExchanges", res.data.exchanges);
            // set stocks (unique)
            let itemsToAdd = onlyUnique(res.data.stocks);
            this.$store.commit("setStocks", itemsToAdd); 
          } else {
            this.$toast.error(this.getCouldNotSetExchangesMessage, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: this.getCouldNotSetExchangesMessage,
              error: true
            });     
          }
        }
      } catch (e) {
        console.error(e);
      }      
    },
    async getXfraData(xfraList) {
      if (!xfraList || 0 === xfraList.length) {
        return;
      }
      const user = this.$store.getters["getUser"];
      // get exchanges and stocks
      try {
        const res = await http.getApiClient(this.getUserType()).get("", {
          params: {
            a: "get_stocks_full_xfra",
            id: user.id,
            key: user.key,
            sc: xfraList.join()
          }
        });
        if (res) {
          // check answer
          if (ApiUtils.isApiResponseInvalidSession(res)) {
            this.$store.commit("setAppStateReady");
            // logout
            setTimeout(
              ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
              500);
            return;
          }
          if (0 === res.data.ec) {
            // add exchange index (hardcoded 3)
            res.data.stocks.forEach(item => {
              // this answer does not comply with the get_exchanges_stocks_ex answer
              item.code = item.sc;
              item.cMIC = 3;
              item.tID = item.stid;
              this.$store.commit("addStock", item);
            })
          } else {
            this.$toast.error(this.getCouldNotSetExchangesMessage, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: this.getCouldNotSetExchangesMessage,
              error: true
            });
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    openWallet() {
      this.$router.push({ name: "Wallet" });
    },
    openDashboard() {
      this.$router.push({ name: "Exchange" });
    },
    openProfile() {
      this.$router.push({ name: "Profile" });
    },
    async getTopTen(what) {
      const user = this.$store.getters["getUser"];
      try {
        // get selected exchange code
        const exchangeCode = UiUtils.getExchangeCodeById(this.getExchanges, this.selectedExchange);
        const res = await http.getApiClient(this.getUserType()).get("", {
          params: {
            a: "get_top_ten",
            id: user.id,
            key: user.key,
            what: what,
            ex: "" === exchangeCode ? null : exchangeCode
          }
        });
        if (res) {
          // check answer
          if (ApiUtils.isApiResponseInvalidSession(res)) {
            this.$store.commit("setAppStateReady");
            // logout
            setTimeout(
              ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
              500);
            return;
          }          
          if (0 === res.data.ec) {
            switch (what) {
              case "gainers": {
                this.$store.commit("setGainers", res.data.list);
                break;
              }
              case "losers": {
                this.$store.commit("setLosers", res.data.list);
                break;
              }
              case "most_traded": {
                this.$store.commit("setMostTraded", res.data.list);
                break;
              }
            }
          } else {
            this.$toast.error(this.getCouldNotReadMessage + what, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: this.getCouldNotReadMessage + what,
              error: true
            });                 
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getTop10Indexes() {
      const user = this.$store.getters["getUser"];
      try {
        // get selected exchange code
        const exchangeCode = UiUtils.getExchangeCodeById(this.getExchanges, this.selectedExchange);
        const res = await http.getApiClient(this.getUserType()).get("", {
          params: {
            a: "get_indexes",
            id: user.id,
            key: user.key,
            xc: "" === exchangeCode ? null : exchangeCode
          }
        });
        if (res) {
          // check answer
          if (ApiUtils.isApiResponseInvalidSession(res)) {
            this.$store.commit("setAppStateReady");
            // logout
            setTimeout(
              ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
              500);
            return;
          }          
          if (0 === res.data.ec) {
            this.$store.commit("setIndexes", res.data.list);
          } else {
            this.$toast.error(this.getCouldNotReadIndexesMessage, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: this.getCouldNotReadIndexesMessage,
              error: true
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getWallet() {
      const user = this.$store.getters["getUser"];
      try {
        if (null === this.$store.getters["getWallet"]) {
          const res = await http.getApiClient(this.getUserType()).get("", {
            params: {
              a: "get_wallet",
              id: user.id,
              key: user.key,
              d: Math.floor((new Date()).getTime() / 1000)
            }
          });
          if (res) {
            // check answer
            if (ApiUtils.isApiResponseInvalidSession(res)) {
              this.$store.commit("setAppStateReady");
              // logout
              setTimeout(
                ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
                500);
              return;
            }            
            if (0 === res.data.ec) {
              this.$store.commit("setWallet", res.data);
            } else {
              this.$toast.error(this.getCouldNotReadWalletMessage, {
                "duration": 5000,
                "pauseOnHover": true
              });
              this.$store.commit("addSystemMessage", {
                date: (new Date()).getTime(),
                mess: this.getCouldNotReadWalletMessage,
                error: true
              });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    async subscribeForSse() {
      // get quotes for watchlist
      const stockCodes = [];
      const watchlists = this.$store.getters["getUserWatchlists"];

      if (watchlists) {
        for (let cnt = 0; cnt < watchlists.length; cnt++) {
          stockCodes.push(UiUtils.extractExchangeCodeFromPairObject(watchlists[cnt].sc));
        }
      }
      const user = this.$store.getters["getUser"];
      try {
        const res = await http.getApiClient(this.getUserType()).get("", {
          params: {
            a: "set_sse",
            id: user.id,
            key: user.key,
            "quote-ex-sts": "off",
            "quote-tr-sts": "on",
            "depth-sts": "off",
            "top10-sts": "on",
            "top10-set": "",
            "top10-typ": "",
            "wallet-sts": "on",
            "phase-sts": "on",
            "quote-sts": "on",
            "quote-set": stockCodes.join()
          }
        });
        if (res) {
          // check answer
          if (0 !== res.data.ec) {
            this.$toast.error(this.getCouldNotSetSseMessage, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: this.getCouldNotSetSseMessage,
              error: true
            });
          }
        } 
      } catch (err) {
        console.error(err);
      }
    },
    async getQuoteTr() {
      if ("" === this.selectedItemCode) {
        return;
      }
      // get quote trading for selected item
      const user = this.$store.getters["getUser"];
      // get exchange code
      const exchanges = this.$store.getters["getExchanges"];
      const itemExchangeCode = UiUtils.getExchangeCodeById(exchanges, this.$store.getters["getSelectedTradingExchangeId"]);      
      try {
        const res = await http.getApiClient(this.getUserType()).get("", {
          params: {
            a: "get_quote_tr",
            id: user.id,
            key: user.key,
            sc: UiUtils.combineExchangeStockPair(itemExchangeCode, this.selectedItemCode)
          }
        });
        if (res) {
          // check answer
          if (ApiUtils.isApiResponseInvalidSession(res)) {
            this.$store.commit("setAppStateReady");
            // logout
            setTimeout(
              ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
              500);
            return;
          }          
          if (0 === res.data.ec) {
            // set quote tr
            this.quoteTrading = res.data;
          } else {
            this.$toast.error(this.getCouldNotReadQuotesMessage, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: this.getCouldNotReadQuotesMessage,
              error: true
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    async setQuoteTr(code) {
      if ("" === code) {
        return;
      }
      // set sse for quote tr
      // get exchange code
      const exchanges = this.$store.getters["getExchanges"];
      const itemExchangeCode = UiUtils.getExchangeCodeById(exchanges, this.$store.getters["getSelectedTradingExchangeId"]);
      await ApiUtils.setQuoteTrading(http, UiUtils, this.$store, itemExchangeCode, code);
    },
    checkHasThisStock(stocks, stockCode, exchangeId) {
      const items = stocks.filter(item => {
        return 0 === item.code.toString().toLowerCase().localeCompare(stockCode.toString().toLowerCase() &&
          parseInt(item.cMIC) === parseInt(exchangeId));
      });
      return (items && 0 < items.length);
    },
    userWatchlistNameClick(watchlist) {
      // save the clicked index/watchlist name
      this.$store.commit("setClickedWatchlistName", watchlist.wln.toString());

      this.activeTop10Item = 'INDEX';
      this.activeUserWatchlist = watchlist.wln;
      const stocks = this.$store.getters["getStocks"];
      const exchanges = this.$store.getters["getExchanges"];
      const concerned = UiUtils.extractStocksInWatchlist(stocks, watchlist, exchanges) || [];
      // we need to take care of index watchlist also for sse
      var mapped = concerned;
      // get currently selected index
      const indexWatchlists = this.getIndexedWatchlists.filter(item => {
        return 0 === item.wln.localeCompare(this.activeIndexWatchlist);
      });
      if (indexWatchlists && 0 < indexWatchlists.length) {
        mapped = mapped.concat(UiUtils.extractStocksInWatchlist(stocks, indexWatchlists[0], exchanges));
      }

      ApiUtils.updateSearchedStocksListUserWatchlist(this.$store, http, UiUtils, mapped, concerned);
    },
    indexWatchlistNameClick(watchlist) {
      this.activeIndexWatchlist = watchlist.wln;
      const stocks = this.$store.getters["getStocks"];
      const exchanges = this.$store.getters["getExchanges"];
      const concerned = UiUtils.extractStocksInWatchlist(stocks, watchlist, exchanges);
      // we need to take care of user watchlist also for sse
      var mapped = concerned;
      // get currently selected watchlist
      const userWatchlists = this.getUserWatchLists.filter(item => {
        return 0 === item.wln.localeCompare(this.activeUserWatchlist);
      });
      if (userWatchlists && 0 < userWatchlists.length) {
        mapped = mapped.concat(UiUtils.extractStocksInWatchlist(stocks, userWatchlists[0], exchanges));
      }

      ApiUtils.updateSearchedStocksListIndexWatchlist(this.$store, http, UiUtils, mapped, concerned);
    },    
    top10ItemClick(name) {
      this.activeTop10Item = name;
    },
    setWatchlistToDelete(name) {
      this.watchlistNameToDelete = name;
      this.watchlistSelectionModalActive = true;
      this.watchlistSelectionModalShow = true;      
    },
    async deleteWatchlist() {
      const user = this.$store.getters["getUser"];
      if ("" === this.watchlistNameToDelete) {
        return;
      }
      // delete watchlist
      try {
        let res = await http.getApiClient(this.getUserType()).get("", {
          params: {
            a: "delete_watchlist",
            id: user.id,
            key: user.key,
            wln: this.watchlistNameToDelete
          }
        });
        if (res) {
          // check answer
          if (ApiUtils.isApiResponseInvalidSession(res)) {
            this.$store.commit("setAppStateReady");
            // logout
            setTimeout(
              ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
              500);
            return;
          }          
          if (0 === res.data.ec) {
            // update user watchlists
            res = await http.getApiClient(this.getUserType()).get("", {
              params: {
                a: "get_watchlist",
                id: user.id,
                key: user.key
              }
            });
            if (0 === res.data.ec) {
              this.$store.commit("updateWatchlists", res.data.wls);
              this.watchlistNameToDelete = "";
              // select new watchlist if any
              if (0 !== res.data.wls.length) {
                this.userWatchlistNameClick(res.data.wls[0]);
              } else {
                // remove all stocks from list
                this.$store.commit("setSearchedStocksUserWatchlist", []);
              }
            }
          } else {
            this.$toast.error(this.getCouldNotDeleteWatchlistMessage, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: this.getCouldNotDeleteWatchlistMessage,
              error: true
            });
          }
        }
      } catch (e) {
        console.error(e);
      }

      this.watchlistSelectionModalActive = false;
      this.watchlistSelectionModalShow = false;
    },
    async getIndexMembers(index, exchangeCode, sortPosition) {
      const user = this.$store.getters["getUser"];
      try {
        let res = await http.getApiClient(this.getUserType()).get("", {
            params: {
              a: "get_indexes_members",
              id: user.id,
              key: user.key,
              idx: index
            }
          });
          if (res) {
            // check answer
          if (ApiUtils.isApiResponseInvalidSession(res)) {
            this.$store.commit("setAppStateReady");
            // logout
            setTimeout(
              ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
              500);
            return;
          }            
            if (0 === res.data.ec) {
              let members = [];
              const responseMembers = res.data.list[0].members;
              responseMembers.forEach(item => {
                members.push(UiUtils.createExchangeStockCodePairObject(exchangeCode, item));
              });
              this.$store.commit("addIndexesWatchlists", {
                index: sortPosition,
                arr: {
                  wln: index,
                  sc: members
                }
              });
            }
          }
        } catch (e) {
          console.error(e);
        }
    },
    async getAllIndexesMembers() {
      if (0 !== this.$store.getters["getIndexesWatchlists"].length) {
        return;
      }
      // cycle in all indexes
      const exchanges = this.$store.getters["getExchanges"];      
      let indexesWatchlists = [];
      if (exchanges && 0 < exchanges?.length) {
        exchanges.sort((a, b) => a.eID - b.eID);
        for (let cnt = 0; cnt < exchanges.length; cnt ++) {
          const exchange = exchanges[cnt];
          // skip XFRA as we return XETR indexes for it
          if (0 === exchange.code.toLowerCase().localeCompare('xfra')) {
            continue;
          }
          for (let indexCnt = 0; indexCnt < exchange.indexes.length; indexCnt++) {
            indexesWatchlists.push(this.getIndexMembers(exchange.indexes[indexCnt].n, exchange.code, indexCnt + 4 * cnt));
          }
        }
      }
      // execute all
      await Promise.all(indexesWatchlists);
    },
    async getAllTopTen() {
      // execute all top 10 data gather
      await Promise.all([this.getTopTen('gainers'), this.getTopTen('losers'),
        this.getTopTen('most_traded'), this.getTop10Indexes()]);
    },
    canDeleteWatchlist(name) {
      if (0 === 'default'.localeCompare(name.toString().toLowerCase())) {
        return false;
      }
      // index names
      const indexes = this.$store.getters["getIndexesWatchlists"];
      if (indexes && 0 < indexes?.length) {
        for (let cnt = 0; cnt < indexes.length; cnt ++) {
          const wl = indexes[cnt];
          if (0 === wl.wln.toString().toLowerCase().localeCompare(name.toString().toLowerCase())) {
            return false;
          }          
        }
      }

      return true;
    },
    getStockName(stockCode, exchangeCode) {
      const stocks = this.$store.getters["getStocks"];
      const indexedStocks = this.$store.getters["getIndexedStocks"];
      const exchanges = this.$store.getters["getExchanges"];
      const exchangeId = UiUtils.getExchangeIdByCode(exchanges, exchangeCode);
      return UiUtils.getStockName(stocks, indexedStocks, stockCode, exchangeId, this.getUserLanguage);
    },
  },
  computed: {
    getWindowsWidth() {
      return this.$store.getters["getAppWindowWidth"] || window.innerWidth;
    },    
    getUserLanguage() {
      return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
    },
    getUserWatchLists() {
      const user = this.$store.getters["getUser"];
      return UiUtils.getUserWatchlists(user);
    },
    getIndexedWatchlists() {
      const indexesWatchlists = this.$store.getters["getIndexesWatchlists"];
      const exchanges = this.$store.getters["getExchanges"];
      // if we currently have a watchlist selected that is an index of a filtered exchange
      // we should select the first watchlist in the filtered list
      const availableIndexes = UiUtils.getIndexedWatchlists(exchanges, indexesWatchlists, this.getSelectedExchangeCode);
      if (this.activeIndexWatchlist 
        && availableIndexes && 0 < availableIndexes.length  
        && !availableIndexes.map(item => item.wln).includes(this.activeIndexWatchlist)) {
        this.indexWatchlistNameClick(availableIndexes[0]);
      }

      return availableIndexes;
    },
    getSelectedExchangeCode() {
      return this.$store.getters["getSelectedExchangeCode"];
    },
    getGainers() {
      const gainers = this.$store.getters["getGainers"].slice();
      switch (this.activeSortingOption) {
        case 0: return gainers;
        case 1: {
          // symbol asc
          return gainers.sort(dynamicSort('sc'));
        }
        case 2: {
          // symbol desc
          return gainers.sort(dynamicSort('-sc'));
        }
        case 3: {
          // name asc
          // add names
          gainers.forEach(item => {
            item.name = this.getStockName(item.sc, item.ex);
          })
          return gainers.sort(dynamicSort('name'));
        }
        case 4: {
          // name desc
          // add names
          gainers.forEach(item => {
            item.name = this.getStockName(item.sc, item.ex);
          })
          return gainers.sort(dynamicSort('-name'));
        }
      }      
      return this.$store.getters["getGainers"];
    },
    getLosers() {
      const losers = this.$store.getters["getLosers"].slice();
      switch (this.activeSortingOption) {
        case 0: return losers;
        case 1: {
          // symbol asc
          return losers.sort(dynamicSort('sc'));
        }
        case 2: {
          // symbol desc
          return losers.sort(dynamicSort('-sc'));
        }
        case 3: {          
          // name asc
          // add names
          losers.forEach(item => {
            item.name = this.getStockName(item.sc, item.ex);
          })
          return losers.sort(dynamicSort('name'));
        }
        case 4: {
          // name desc
          // add names
          losers.forEach(item => {
            item.name = this.getStockName(item.sc, item.ex);
          })
          return losers.sort(dynamicSort('-name'));
        }
      }       
      return this.$store.getters["getLosers"];
    },
    getMostTraded() {
      const mostTraded = this.$store.getters["getMostTraded"].slice();
      switch (this.activeSortingOption) {
        case 0: return mostTraded;
        case 1: {
          // symbol asc
          return mostTraded.sort(dynamicSort('sc'));
        }
        case 2: {
          // symbol desc
          return mostTraded.sort(dynamicSort('-sc'));
        }
        case 3: {
          // name asc
          // add names
          mostTraded.forEach(item => {
            item.name = this.getStockName(item.sc, item.ex);
          })
          return mostTraded.sort(dynamicSort('name'));
        }
        case 4: {
          // name desc
          // add names
          mostTraded.forEach(item => {
            item.name = this.getStockName(item.sc, item.ex);
          })
          return mostTraded.sort(dynamicSort('-name'));
        }
      }         
      return this.$store.getters["getMostTraded"];
    },
    getIndexes() {
      const indexes = this.$store.getters["getIndexes"].slice();
      switch (this.activeSortingOption) {
        case 0: return indexes;
        case 1, 3: {
          // symbol asc
          return indexes.sort(dynamicSort('sc'));
        }
        case 2, 4: {
          // symbol desc
          return indexes.sort(dynamicSort('-sc'));
        }
      }
      return this.$store.getters["getIndexes"];
    },
    getSelectedTradingCode() {
      return this.$store.getters["getSelectedTradingCode"];
    },
    getExchanges() {
      return this.$store.getters["getExchanges"];
    },
    getSearchPlaceholder() {
      return Translations.getDashboardLabel(this.getUserLanguage);
    },
    getStockLabel() {
      return Translations.getStockLabel(this.getUserLanguage);
    },
    getBuyLabel() {
      return Translations.getBuyLabel(this.getUserLanguage);
    },
    getSellLabel() {
      return Translations.getSellLabel(this.getUserLanguage);
    },
    getLastDealLabel() {
      return Translations.getLastDealLabel(this.getUserLanguage);
    },
    getTurnoverLabel() {
      return Translations.getTurnoverLabel(this.getUserLanguage);
    },
    getIndexMembersLabel() {
      return Translations.getIndexMembersLabel(this.getUserLanguage);
    },    
    getGainersLabel() {
      return Translations.getGainersLabel(this.getUserLanguage);
    },
    getLosersLabel() {
      return Translations.getLosersLabel(this.getUserLanguage);
    },
    getMostTradedLabel() {
      return Translations.getMostTradedLabel(this.getUserLanguage);
    },
    getIndexesLabel() {
      return Translations.getIndexesLabel(this.getUserLanguage);
    },
    getIndexLabel() {
      return Translations.getIndexLabel(this.getUserLanguage);
    },
    getChangeLabel() {
      return Translations.getChangeLabel(this.getUserLanguage);
    },
    getValueLabel() {
      return Translations.getValueLabel(this.getUserLanguage);
    },
    getConfirmDeleteValueLabel() {
      return Translations.getDeleteWatchlistLabel(this.getUserLanguage);
    },
    getCloseLabel() {
      return Translations.getCloseLabel(this.getUserLanguage);
    },
    getConfirmLabel() {
      return Translations.getConfirmLabel(this.getUserLanguage);
    },
    getCouldNotSetExchangesMessage() {
      return Translations.getCouldNotSetExchangesMessage(this.getUserLanguage);
    },
    getCouldNotReadMessage() {
      return Translations.getCouldNotReadMessage(this.getUserLanguage);
    },
    getCouldNotReadIndexesMessage() {
      return Translations.getCouldNotReadIndexesMessage(this.getUserLanguage);
    },
    getCouldNotReadWalletMessage() {
      return Translations.getCouldNotReadWalletMessage(this.getUserLanguage);
    },
    getCouldNotReadQuotesMessage() {
      return Translations.getCouldNotReadQuotesMessage(this.getUserLanguage);
    },
    getCouldNotSetSseMessage() {
      return Translations.getCouldNotSetSseMessage(this.getUserLanguage);
    },
    getCouldNotDeleteWatchlistMessage() {
      return Translations.getCouldNotDeleteWatchlistMessage(this.getUserLanguage);
    },
    getAllExchangesLabel() {
      return Translations.getAllExchangesLabel(this.getUserLanguage);
    },
    getReestablishingConnectionLabel() {
      return Translations.getReestablishingConnectionLabel(this.getUserLanguage);
    },
    getReestablishedConnectionLabel() {
      return Translations.getReestablishedConnectionLabel(this.getUserLanguage);
    }, 
    getSearchedStocks() {
      return this.$store.getters["getSearchedStocksUserWatchlist"];
    },
    getWatchlistLabel() {
      return Translations.getWatchlistLabel(this.getUserLanguage);
    },
    getSortSymbolAscLabel() {
      return Translations.getSortSymbolAscLabel(this.getUserLanguage);
    },
    getSortSymbolDescLabel() {
      return Translations.getSortSymbolDescLabel(this.getUserLanguage);
    },
    getSortNameAscLabel() {
      return Translations.getSortNameAscLabel(this.getUserLanguage);
    },
    getSortNameDescLabel() {
      return Translations.getSortNameDescLabel(this.getUserLanguage);
    },
    getSortDefaultLabel() {
      return Translations.getSortDefaultLabel(this.getUserLanguage);
    },
    getInvalidSessionLabel() {
      return Translations.getInvalidSessionLabel(this.getUserLanguage);
    },
    stopSseState() {
      return this.$store.getters["getStopSse"];
    }    
  },
  async mounted() {
    this.$store.commit("setAppStateNotReady");
    // get wallet, exchanges and quotes
    await this.getExchangesAndStocks();
    // open default watchlist when loaded first time
    // set selected watchlist if none
    const userWatchlists = this.getUserWatchLists;
    if (userWatchlists && 0 < userWatchlists.length) {
      // we will download only data for watchlists if any on load
      // get XFRA data for watchlists
      const xfraList = [];
      userWatchlists.forEach(element => {
        element.sc?.forEach(stock => {
          if (0 === stock?.xc.toString().toUpperCase().localeCompare('XFRA')) {
            xfraList.push(stock.sc);
          }
        });
      });
      await this.getXfraData(xfraList);
      this.userWatchlistNameClick(userWatchlists[0]);
    } 
    await Promise.all([this.getWallet(), this.subscribeForSse()]);
    this.$store.commit("setAppStateReady");

    window.setTimeout(async function () {
      // set default top 10 list every time we load the component
      this.top10ItemClick('INDEX');
      await Promise.all([this.getAllTopTen(), this.getAllIndexesMembers()]);
      const indexesWatchlists = this.$store.getters["getIndexesWatchlists"];
      if (indexesWatchlists && 0 < indexesWatchlists.length) {
        this.indexWatchlistNameClick(indexesWatchlists[0]);
      }
    }.bind(this), 500);

    // start sse
    this.hasEvents = false;    
    this.setupStream();
    // start sse monitor
    this.sseVerifier = window.setInterval(function () {
      if (EventSource.OPEN !== this.sseClient?.readyState || !this.hasEvents) {
        // close current client if any
        if (this.sseClient) {
          try {
            this.sseClient.close();
          } catch (err) {
            console.error(err);
          }
          this.sseClient = null;
        }
        this.setupStream();
      }
    }.bind(this), 3000);      
  },
  unmounted() {
    // stop verifier
    if (this.sseVerifier) {
      clearInterval(this.sseVerifier);
    }    
    // close sse
    if (this.sseClient) {
      try {
        this.sseClient.close();
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>

<style scoped>


  tbody {
    overflow-y: auto;
    overflow-x: hidden;
  }

  th {
    width: 100%;
  }

  .name-column {
    vertical-align: middle;
    text-align: center;  
  }
  .data-column {
    vertical-align: middle;
    text-align: center;
  }
  
  .large-data-column {
    min-width: 9rem;
    vertical-align: middle;
    text-align: center;
    padding-right: 2rem;    
  }
  .watchlists-list {
    max-height: 41rem;
  }

  #top10-column #watchlists-column #trading-column {
    min-height: 30rem;
    max-height: 30rem;
  }

    .stock-data {
        vertical-align: middle;
        text-align: end;
        display: table-cell;
        cursor: pointer;
    }

    .stock-name {
        vertical-align: middle;
        text-align: start;
    }

    .greenColor {
        color: #74b21f;
    }

    .redColor {
        color: #ff0000;
    }

    .whiteColor {
        color: #ffffff;
    }

    .blackColor {
        color: #000000;
    }

    .greenBackgroundColor {
        background: #74b21f;
        border: 1px solid #74b21f;
        border-radius: 8px;
    }

    .redBackgroundColor {
        background: #ff0000;
        border: 1px solid #ff0000;
        border-radius: 8px;
    }

    .whiteBackgroundColor {
        background: #ffffff;
        border: 1px solid black;
        border-radius: 8px;
    }
.xxsmall-font-size {
    font-size: xx-small;
}

.xsmall-font-size {
    font-size: x-small;
}
</style>
